import { Link } from "@remix-run/react";

type SidebarProps = {
  popularTags: string[];
};

export default function Sidebar({ popularTags }: SidebarProps) {
  return (
    <div className="col-md-3 ms-auto">
      <aside className="sidebar">
        <div className="card mb-4">
          <div className="card-body">
            <h4 className="card-title">About</h4>
            <p className="card-text">
              Hey there! I’m a millennial girl heading into the last year of my
              20’s. You’d think I’d have it all figured out or close to to it.
              Truth is though, I’m far from having my life together but this
              year I’m determined to put some things into place. Read along,
              laugh along, and cry along as you see me try to navigate through
              the unknown.
            </p>
          </div>
        </div>
      </aside>

      {popularTags.length > 0 ? (
        <aside className="sidebar sidebar-sticky">
          <div className="card mb-4">
            <div className="card-body">
              <h4 className="card-title">Tags</h4>
              {popularTags.map((tag) => (
                <Link
                  to={`/posts?tag=${tag}`}
                  className="btn btn-light btn-sm mb-1 mx-1"
                  key={tag}
                >
                  {tag}
                </Link>
              ))}
            </div>
          </div>
        </aside>
      ) : null}
    </div>
  );
}
